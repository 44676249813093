import { TableRow } from "../models/common-table";

export const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
export type SortOrder = "ASC" | "DESC";

export function defaultSerializer(row: TableRow, keys: string[]): string | null {
  if ((keys || []).length === 0) {
    return null;
  }
  return keys
    .map((heading: string, hIdx: number) => {
      const cell = row[hIdx];
      if (!cell) {
        return "";
      }
      return cell.rawValue ?? (Array.isArray(cell.value) ? cell.value[0] : cell.value);
    })
    .map((i) => i ?? "") // fetch value if present
    .map((i) => (typeof i === "string" ? i : JSON.stringify(i))) // try coerce to string
    .map((i) => i.replace(/"/g, '""')) // escape double quote
    .map((i) => (/[",]/.test(i) ? '"' + i + '"' : i)) // add double quote string identifer
    .join(",");
}
