<app-looker-studio-navigation *ngIf="!sidebar" (sizeChanged)="widthRatio = $event.value"></app-looker-studio-navigation>

<div
  class="mx-auto"
  style="padding: 5px {{ sidebar ? '0' : '60px' }}; max-width: {{ 95 * (sidebar ? 1 : widthRatio) }}vw"
>
  <div class="d-flex flex-row align-items-start justify-content-between mt-{{ sidebar ? 0 : 5 }}">
    <div style="width: 130px"></div>

    <h2 class="h2 text-center">VCloud AI {{ sidebar ? "" : "Assistant" }}</h2>

    <div class="d-flex flex-row justify-content-between align-items-start px-3" style="width: 110px">
      <button
        tooltip="Reset conversation"
        class="btn clickable m-1"
        *ngIf="viewType === 'default' && (responses?.length ?? 0) > 1"
        (click)="clearConversation()"
      >
        <app-vficon class="clickable" size="20px" iconPack="source-system-icons" iconName="refresh"></app-vficon>
      </button>

      <button
        [class.clickable]="!loading"
        tooltip="Customize"
        *ngIf="viewType === 'default'"
        class="btn clickable ai-customise m-1"
        (click)="switchToUserPreferencesView()"
      >
        <app-vficon
          [class.clickable]="!loading"
          size="20px"
          iconPack="source-system-icons"
          iconName="settings"
        ></app-vficon>
      </button>

      <button
        tooltip="Close"
        class="btn clickable m-1"
        *ngIf="sidebar && viewType === 'default'"
        (click)="closeLLM.emit(true)"
      >
        <app-vficon size="20px" iconPack="source-system-icons" iconName="close"></app-vficon>
      </button>
    </div>
  </div>

  <form
    *ngIf="viewType === 'configure'"
    [formGroup]="configureForm"
    (ngSubmit)="submitUserPreferences()"
    class="p-4 d-flex flex-column justify-content-start align-items-center"
  >
    <div class="mt-4">
      <label class="d-block">Preferred Cloud Provider(s)</label>
      <input formControlName="cloudProvider" type="text" placeholder="e.g. AWS" style="width: 360px" />
    </div>

    <div class="mt-4">
      <label class="d-block">Your Role(s)</label>
      <input formControlName="role" placeholder="e.g. data engineer" type="text" style="width: 360px" />
    </div>

    <div class="mt-4">
      <label class="d-block">Your Goal(s)</label>
      <input
        formControlName="goals"
        type="text"
        placeholder="e.g. understanding cost consumption"
        style="width: 360px"
      />
    </div>

    <div class="mt-4">
      <label class="d-block">Your Application(s)</label>
      <input
        formControlName="application"
        type="text"
        placeholder="e.g. e-commerce angular application"
        style="width: 360px"
      />
    </div>

    <div class="mt-4">
      <label class="d-block">Additional Instructions</label>
      <input
        formControlName="instructions"
        type="text"
        placeholder="e.g. please respond in a concise way"
        style="width: 360px"
      />
    </div>

    <div class="d-flex mt-4">
      <button class="btn clickable btn-primary" type="submit">Save</button>
      <button class="btn clickable btn-secondary ml-2" type="button" (click)="resetForm()">Reset</button>
    </div>
  </form>

  <ol *ngIf="viewType === 'default'" class="list-unstyled d-block mx-auto mt-{{ sidebar ? 3 : 5 }}">
    <ng-container *ngFor="let r of responses; index as idx">
      <li
        *ngIf="r?.type === 'text' || r?.type === 'error' || r?.type === 'api_data'"
        [ngClass]="{
          'data-card': r?.type === 'api_data',
          'message-card': r?.type === 'text' || r?.type === 'error',
          'user-card': r?.who === 'User',
          'system-card': r?.who !== 'User'
        }"
        class="w-100 llm-response position-relative h5 mb-5 rounded custom-card"
      >
        <p
          style
          class="font-weight-bold py-2 px-3 d-flex flex-row justify-content-between align-items-center flex-nowrap m-0 mb-2 bg-{{
            r.who === 'User' ? 'light' : 'light'
          }}"
        >
          <span class="d-flex align-items-center mb-2">
            <app-vficon
              class="mr-2 clickable"
              *ngIf="r?.who === AI_NAME"
              iconName="default"
              iconPack="source-tobi-icons"
              size="25px"
            ></app-vficon>
            <app-vficon
              *ngIf="r?.who === 'User'"
              iconName="talk"
              size="25px"
              iconPack="source-mid-render-light-icons"
              class="mr-2 clickable"
            ></app-vficon>
            <span
              [class.clickable]="r.who === 'VCloudSmart API'"
              [tooltip]="TOOLTIPS[r.who]"
              class="h4 position-relative"
              style="top: 4px"
              >{{ sidebar ? r?.who.split(" ").slice(-1)[0] : r?.who }}</span
            >
          </span>
          <span>
            <span
              class="btn btn-success clickable tag d-none {{ sidebar ? '' : 'd-md-inline' }}"
              *ngFor="let ft of r.filterTags ?? []"
            >
              {{ ft }}
            </span>

            <span class="btn btn-info clickable tag d-none {{ sidebar ? '' : 'd-md-inline' }}" *ngIf="r.analysing">
              Analysing...
            </span>

            <span class="btn btn-warning clickable tag d-none {{ sidebar ? '' : 'd-md-inline' }}" *ngIf="r.limitFmt">
              Limit: {{ r.limitFmt }}
            </span>

            <span class="btn d-none d-sm-inline btn-success clickable tag" *ngIf="r.dateRange">
              {{ r.dateRange }}
            </span>

            <ng-container *ngIf="!sidebar">
              <span
                class="btn btn-warning clickable tag d-none d-md-inline"
                tooltip="Change to another cloud provider"
                [class.clickable]="
                  (r?.queryTypes ?? []).includes('api_payload') || (r?.queryTypes ?? []).includes('casual_interactions')
                "
                (click)="changeUserQueryCloudProvider(r, csp, idx)"
                *ngFor="let csp of r.cloudProviders ?? []"
              >
                {{ csp | uppercase }}
              </span>
            </ng-container>

            <span
              [class.clickable]="
                (r?.queryTypes ?? []).includes('api_payload') || (r?.queryTypes ?? []).includes('casual_interactions')
              "
              [class.d-md-inline]="!sidebar"
              class="btn btn-primary tag d-none"
              tooltip="Click to switch to a different domain"
              *ngFor="let d of r.domains ?? []"
              (click)="
                (r?.queryTypes ?? []).includes('casual_interactions') || (r?.queryTypes ?? []).includes('api_payload')
                  ? changeUserQueryDomain(r, d, idx)
                  : null
              "
              [ngSwitch]="d"
            >
              {{ d }}
            </span>

            <span
              (click)="changeUserQueryType(r, qt, idx)"
              [tooltip]="qt === 'casual_interactions' ? 'Get data instead' : 'Ask question instead'"
              class="btn btn-secondary clickable tag d-none d-sm-inline"
              *ngFor="let qt of r.queryTypes ?? []"
              [ngSwitch]="qt"
            >
              <ng-container *ngSwitchCase="'api_payload'">request for data</ng-container>
              <ng-container *ngSwitchCase="'casual_interactions'">question</ng-container>
              <ng-container *ngSwitchCase="'suggest_reports'">which page?</ng-container>
            </span>

            <app-vficon
              size="20px"
              *ngIf="r.type === 'text' || r.type === 'error' || r.type === 'api_data'"
              (click)="copy(r)"
              tooltip="Copy"
              class="mr-2 clickable"
              style="position: relative; bottom: 3px"
              iconPack="source-system-icons"
              iconName="copy"
            ></app-vficon>

            <app-vficon
              size="25px"
              *ngIf="r?.who === 'User' && !r.isEditable"
              tooltip="Edit"
              (click)="editUserQuery(idx, r)"
              [class.disabled]="!!loading"
              [class.clickable]="!loading"
              class="mr-2"
              style="position: relative; bottom: 3px"
              iconPack="source-system-icons"
              iconName="edit"
            ></app-vficon>

            <app-vficon
              size="25px"
              *ngIf="r?.who === 'User' && r.isEditable"
              (click)="editUserQuery(idx, r)"
              [class.clickable]="!loading"
              tooltip="Save"
              [class.disabled]="!!loading"
              class="mr-2"
              style="position: relative; bottom: 3px"
              iconPack="source-system-icons"
              iconName="tick"
            ></app-vficon>

            <app-vficon
              *ngIf="r?.who === 'User'"
              size="25px"
              (click)="retryUserQuery(idx, r)"
              [class.clickable]="!loading"
              [class.disabled]="!!loading"
              class="mr-3"
              style="position: relative; bottom: 3px"
              tooltip="Retry"
              iconPack="source-system-icons"
              iconName="refresh"
            ></app-vficon>

            <app-vficon
              size="25px"
              *ngIf="idx > 0 && r?.who === 'User' && !sidebar"
              (click)="removeResponseFromConversation(idx)"
              [class.clickable]="!loading"
              [class.disabled]="!!loading"
              class="mr-0"
              style="position: relative; bottom: 3px"
              iconPack="source-system-icons"
              tooltip="Remove bubble"
              iconName="close"
            ></app-vficon>

            <button
              *ngIf="r?.who !== 'User'"
              class="btn clickable"
              (click)="r.collapsed = !r.collapsed"
              [disabled]="!!loading"
              [class.disabled]="!!loading"
              [ngSwitch]="!!r?.collapsed"
            >
              <app-vficon
                *ngSwitchCase="false"
                size="25px"
                class="clickable"
                style="position: relative; bottom: 3px"
                iconPack="source-system-icons"
                tooltip="Collapse"
                iconName="chevron-down"
              ></app-vficon>
              <app-vficon
                *ngSwitchCase="true"
                size="25px"
                class="clickable"
                tooltip="Expand"
                style="position: relative; bottom: 3px"
                iconPack="source-system-icons"
                iconName="chevron-up"
              ></app-vficon>
            </button>
          </span>
        </p>

        <div class="p-3" *ngIf="!r?.collapsed">
          <ng-container *ngIf="r?.type === 'api_data'">
            <ul class="nav nav-tabs d-flex flex-row justify-content-center mb-5 pcs-nav-tabs">
              <li
                *ngIf="canDisplayColumnChart(r)"
                class="nav-item {{ shouldDisplayColumnChart(r) ? 'active disabled' : '' }}"
              >
                <button
                  class="nav-link clickable {{ shouldDisplayColumnChart(r) ? ' active disabled ' : '' }}"
                  (click)="handleSelectTab(r, 'Column Chart')"
                  [attr.data-header-type]="'Column Chart'"
                >
                  Column Chart
                </button>
              </li>
              <li
                *ngIf="canDisplayPieChart(r)"
                class="nav-item {{ shouldDisplayPieChart(r) ? 'active disabled' : '' }}"
              >
                <button
                  class="nav-link clickable {{ shouldDisplayPieChart(r) ? ' active disabled ' : '' }}"
                  (click)="handleSelectTab(r, 'Pie Chart')"
                  [attr.data-header-type]="'Pie Chart'"
                >
                  Pie Chart
                </button>
              </li>
              <li *ngIf="canDisplayTable(r)" class="nav-item {{ shouldDisplayTable(r) ? ' active disabled ' : '' }}">
                <button
                  class="nav-link clickable {{ shouldDisplayTable(r) ? 'active disabled' : '' }}"
                  (click)="handleSelectTab(r, 'Table')"
                  [attr.data-header-type]="
                    r?.tableRows?.length === 1 && r?.tableColumns?.length === 1 ? 'Result' : 'Table'
                  "
                >
                  {{ r?.tableRows?.length === 1 && r?.tableColumns?.length === 1 ? "Result" : "Table" }}
                </button>
              </li>
              <li *ngIf="canDisplayPayload(r)" class="nav-item {{ shouldDisplayPayload(r) ? 'active disabled' : '' }}">
                <button
                  class="nav-link clickable {{ shouldDisplayPayload(r) ? ' active disabled ' : '' }}"
                  (click)="handleSelectTab(r, 'Payload')"
                >
                  API Payload
                </button>
              </li>
            </ul>

            <ng-container [ngSwitch]="shouldDisplayPayload(r)">
              <p
                *ngSwitchCase="true"
                class="ml-{{ sidebar ? 1 : 5 }}"
                style="white-space: pre; font-family: monospace; font-size: 1rem; overflow: scroll"
              >
                {{ JSON.stringify(r.payload, null, sidebar ? 2 : 4) }}
              </p>

              <ng-container
                [ngSwitch]="r?.tableRows?.length === 0 || r?.tableColumns?.length === 0"
                *ngSwitchCase="false"
              >
                <p class="text-center mx-auto" *ngSwitchCase="true">No matching data could be found.</p>

                <ng-container *ngSwitchCase="false">
                  <google-chart
                    *ngIf="shouldDisplayPieChart(r) || shouldDisplayColumnChart(r)"
                    (error)="handleChartError(r)"
                    [type]="shouldDisplayPieChart(r) ? 'PieChart' : 'ColumnChart'"
                    [options]="
                      shouldDisplayColumnChart(r)
                        ? r?.columnChartOptions ?? columnChartOptions
                        : r?.pieChartOptions ?? pieChartOptions
                    "
                    style="width: 100%"
                    class="mb-0"
                    [dynamicResize]="true"
                    [columns]="r?.chartColumns ?? []"
                    (select)="drillDown($event, r)"
                    [data]="r?.chartRows ?? []"
                  ></google-chart>

                  <p class="text-danger text-center m-0 mb-2 mt-1" *ngIf="r?.tableRows?.length >= ROWS_PER_PAGE">
                    <strong>WARNING</strong>: response may have been truncated to the first
                    {{ looker.formatNumber(Math.max(ROWS_PER_PAGE, r?.tableRows?.length ?? 0)) }} rows. Click

                    <button (click)="fetchNextPage(r)" class="btn clickable btn-link p-0 m-0">here</button> to fetch
                    next page.
                  </p>

                  <app-common-table
                    [tableName]="getTableName(r, idx)"
                    *ngIf="shouldDisplayTable(r)"
                    [columns]="r?.tableColumns ?? []"
                    [rows]="r?.tableRows ?? []"
                    [inputFilters]="r?.tableFilters ?? []"
                    [showFilterAccordian]="(r?.tableRows?.length ?? 0) > 5"
                    (filtersChanged)="r.tableFilters = $event"
                    [showColumnSelectAccordian]="(r?.tableColumns?.length ?? 0) > 3"
                    [loading]="false"
                    [exactRows]="r?.tableRows?.length ?? 0"
                    [pageSize]="5"
                  ></app-common-table>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <div
            [innerHTML]="r.message"
            *ngIf="!r.isEditable && (r?.type === 'error' || r?.type === 'text')"
            class="w-100 d-inline generatedHtml"
          ></div>

          <textarea
            *ngIf="r.isEditable && r?.type === 'text' && r.who === 'User'"
            [(ngModel)]="r.message"
            class="w-100 d-inline"
          ></textarea>
        </div>
      </li>
    </ng-container>
  </ol>

  <div
    *ngIf="!loading && viewType === 'default'"
    class="{{
      sidebar
        ? 'd-flex flex-column align-items-center justify-content-start p-0 mt-5'
        : 'd-flex flex-column flex-xl-row align-items-center align-items-xl-start justify-content-start justify-content-xl-center p-0 mt-5'
    }}"
  >
    <div *ngIf="(suggestedURLs?.length ?? 0) > 0" class="{{ sidebar ? 'mb-4' : 'mr-4' }}">
      <h3 class="h3 text-center">Related Reports</h3>
      <ul
        class="{{
          sidebar
            ? 'list-unstyled d-flex mx-auto flex-column flex-nowrap align-items-center mt-4'
            : 'list-unstyled d-flex mx-auto flex-column flex-md-row flex-nowrap justify-content-md-start align-items-center mt-4'
        }}"
      >
        <li
          *ngFor="let e of suggestedURLs"
          class="ml-4 d-flex flex-column justify-content-between align-items-start mb-2 p-4 custom-card links-card system-card"
          style="width: 220px; height: 140px"
        >
          <div>
            <h4 class="h4">
              {{ decodeURIComponent(e?.Name) }}
            </h4>
            <h5 class="h5">{{ e?.CloudProvider ?? "" }} {{ decodeURIComponent(e?.Section ?? "") }}</h5>
          </div>
          <a
            class="btn btn-primary clickable"
            [fragment]="e?.Fragment"
            [queryParams]="e?.Query"
            [routerLink]="(e?.URL ?? '').replace('%28', '(').replace('%29', ')')"
            >Explore</a
          >
        </li>
      </ul>
    </div>

    <div *ngIf="(exampleQuestions?.length ?? 0) > 0" class="suggested-questions">
      <div class="d-flex flex-row justify-content-between flex-nowrap">
        <span style="width: 30px"></span>
        <h3 class="h3 text-center">Suggested Questions</h3>
        <button
          tooltip="Reload example questions"
          (click)="retryPotentialQuestions()"
          [class.clickable]="!loading"
          class="btn"
          style="width: 30px"
        >
          <app-vficon
            [class.clickable]="!loading"
            size="20px"
            iconPack="source-system-icons"
            iconName="refresh"
          ></app-vficon>
        </button>
      </div>
      <ul class="list-unstyled d-flex mx-auto flex-column flex-nowrap justify-content-start align-items-center mt-4">
        <li *ngFor="let e of exampleQuestions" class="w-100 mb-2">
          <button
            [disabled]="!!loading"
            (click)="submitUserQuery(e, MODEL_EXPENSIVE)"
            class="d-block h4 custom-button clickable w-100"
            [innerHTML]="e"
          ></button>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="!!loading">
    <img
      style="height: 100px"
      id="vf-loader"
      class="d-block m-auto"
      src="/assets/images/spinner_logo.svg"
      alt="spinner"
    />
    <p class="text-center mt-3" [ngSwitch]="loading">
      <ng-container *ngSwitchCase="'analyse_query'"> Determining query type... </ng-container>
      <ng-container *ngSwitchCase="'suggested_reports'">
        Determining query type ✓<br />
        Suggesting relevant reports...
      </ng-container>
      <ng-container *ngSwitchCase="'generated_api_payload'">
        Determining query type ✓<br />
        Generating query...
      </ng-container>
      <ng-container *ngSwitchCase="'api_data'">
        Determining query type ✓<br />
        Generating query ✓<br />
        Getting data...
      </ng-container>
      <ng-container *ngSwitchCase="'potential_questions'"> Suggesting questions... </ng-container>
      <ng-container *ngSwitchCase="'text'">
        Determining query type ✓<br />
        Interpreting...
      </ng-container>
      <ng-container *ngSwitchCase="'user_info'">Getting user info...</ng-container>
      <ng-container *ngSwitchDefault>Processing...</ng-container>
    </p>
  </div>

  <form class="p-0 m-0" *ngIf="viewType === 'default'">
    <div class="textarea-wrapper">
      <textarea
        placeholder="e.g. What is my cost consumption in AWS for the past 6 months?"
        class="custom-textarea"
        [rows]="sidebar ? 2 : 1"
        [disabled]="!!loading"
        [class.disabled]="!!loading"
        [(ngModel)]="userQuery"
        [ngModelOptions]="{ standalone: true }"
        (input)="onTextareaInput($event)"
        (keydown)="onTextareaKeydown($event, userQuery)"
      ></textarea>
      <button
        [class.invisible]="!!loading || !userQuery.trim()"
        [class.disabled]="!!loading || !userQuery.trim()"
        [class.clickable]="!loading && !!userQuery.trim()"
        (click)="submitUserQuery(userQuery, MODEL_EXPENSIVE)"
        [disabled]="!!loading || !userQuery.trim()"
        type="submit"
        class="submit-button"
      >
        <app-vficon
          [class.clickable]="!loading && !!userQuery.trim()"
          style="position: relative; bottom: 4px"
          size="30px"
          iconPack="source-hifi-light-icons"
          iconName="arrow-up"
        ></app-vficon>
      </button>
    </div>
  </form>
</div>
