import { FilterValue } from "./filtering";

export enum CloudProvider {
  aws = "aws",
  gcp = "gcp",
  oci = "oci",
  drcc = "drcc",
  azure = "azure",
  other = "other",
}

export enum ProjectionOperation {
  sum = "SUM",
  stdev_pop = "STDDEV_POP",
  stdev_sample = "STDDEV_SAMP",
  var_pop = "VAR_POP",
  var_sample = "VAR_SAMP",
  avg = "AVG",
  count = "COUNT",
  min = "MIN",
  max = "MAX",
  date_trunc = "DATE_TRUNC",
  average = "AVG",
  array_agg = "ARRAY_AGG",
  any_value = "ANY_VALUE",
  json_value = "JSON_VALUE",
  case = "CASE",
}

export interface ConditionClause {
  Operand1: string;
  Operator?: FilterOperator;
  Operand2?: string;
}

export interface NextConditionClause {
  Condition: ConditionClause;
  LogicalOperator?: FilterLogicalOperator;
  NextCondition?: ConditionClause;
}

export interface CaseConditions {
  Condition: ConditionClause;
  LogicalOperator?: FilterLogicalOperator;
  NextCondition?: NextConditionClause;
  Value: ConditionClause;
}
[];

export interface ProjectionCaseClauses {
  Conditions: CaseConditions;
  Else: ConditionClause;
  WrapWith?: "SUM" | "COUNT" | "AVG" | "MAX" | "MIN";
}
export type FilterOperator =
  | "IN"
  | "NOT IN"
  | "LIKE"
  | "NOT LIKE"
  | "IS"
  | "IS NOT"
  | "!="
  | ">="
  | ">"
  | "<"
  | "<="
  | "="
  | "<>";

export enum FiltersType {
  // use when the column is of type DATETIME or TIMESTAMP
  timestamp = "TIMESTAMP",

  // use when the column is of type DATE
  date = "DATE",

  string = "STRING",
  bool = "BOOL",
  int64 = "INT64",
  float64 = "FLOAT64",
}

// vCloud smart types
export interface VCloudSmartResults {
  Group: string[];
  Value: number[];
}

export interface VCloudSmartResponse {
  Results: VCloudSmartResults[];
}

export type FilterLogicalOperator = "OR" | "AND";

export interface QueryMetadata {
  billing_tier?: number;
  cache_hit?: boolean;
  estimated_bytes_processed?: number;
  etag?: string;
  job_id?: string;
  location?: string;
  project?: string;
  query?: string;
  slot_millis?: number;
  total_bytes_billed?: number;
  total_bytes_processed?: number;
  cost?: string;
  big_query_cost?: string;
  cloud_run_cost?: string;
  api_gateway_cost?: string;
  egress_cost?: string;
}

export interface QueryFilterSimple {
  Type: FiltersType;
  Cast?: boolean;
  Attribute: string;
  Operator: FilterOperator;
  Table?: string;
  Value: FilterValue | FilterValue[];
}

export interface FilterableL2 {
  IsNegated?: boolean;
  FiltersJoinWith?: FilterLogicalOperator;
  Filters: QueryFilterSimple[];
}

export interface FilterableL1 {
  IsNegated?: boolean;
  FiltersJoinWith?: FilterLogicalOperator;
  Filters?: (QueryFilterSimple | FilterableL2)[];
}

// export type JoinType = "INNER" | "FULL" | "LEFT" | "RIGHT" | "CROSS";
export type JoinType = "LEFT" | "RIGHT" | "CROSS";
export type CastAs = "INT64" | "FLOAT64" | "BOOL" | "STRING" | "TIMESTAMP" | "DATE";

export interface JoinSpec {
  Type: JoinType;
  DataStore: string;
  On: {
    Left: string;
    LeftCastAs?: FiltersType;
    Right: string;
    RightCastAs?: FiltersType;
    Operator: FilterOperator;
  };
}

export type SortOrder = "ASC" | "DESC";

export interface SelfJoin {
  Attribute: string;
  Table?: string;
  Type?: JoinType;
  Unnest?: boolean;
}

export interface QueryProjection {
  Attribute?: string;
  Operation?: ProjectionOperation;
  OperationArgs?: Array<string>;
  CaseClauses?: ProjectionCaseClauses;
  Distinct?: boolean;
  CastAs?: CastAs;
}

export interface GroupByPayload extends FilterableL1 {
  CloudProvider: CloudProvider;
  DataStore: string;
  MLModel?: {
    Anomaly?: {
      probability: number;
    };
    Forecast?: object;
  };
  Distinct?: true;
  Limit?: number;
  SelectGroups?: boolean;
  SelfJoin?: SelfJoin;
  SelfJoins?: Array<SelfJoin>;
  Offset?: number;
  OrderBy?: Array<string>;
  Join?: JoinSpec;
  SortOrder?: SortOrder;
  Projection?: QueryProjection[];
  ProcedureParameters?: {
    Name: string;
    Type: string;
    Value: string;
  }[];
  GroupBy?: string[];
  GroupByV2?: {
    Attribute: string;
    Table?: string;
  }[];
}

export type TableId = "Table_1" | "Table_2";

export interface Level {
  label?: string;
  id: string;
  type?: FiltersType;
  table?: TableId;
  operation?: ProjectionOperation;
  operation_args?: Array<string>;
  isDate?: boolean;
  isHour?: boolean;
  isDay?: boolean;
}
